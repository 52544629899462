<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-4 shadow"
        >
          <div class="p-2 text-center" v-if="loadingReport">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>
          <form v-else>
            <div class="row mb-4">
              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="date">Date</label>
                  <input
                    type="text"
                    v-model="date"
                    id="date"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="Name">Name</label>
                  <input
                    type="text"
                    :value="`${user.firstname} ${user.lastname}`"
                    id="Name"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="dept">Department</label>
                  <input
                    type="text"
                    :value="`${user.role.toUpperCase()}`"
                    id="dept"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-5">
                <div class="form-group">
                  <label for="prevScore"
                    >Total Performance Score for the previous week</label
                  >
                  <input
                    type="text"
                    v-model="prevScore"
                    id="prevScore"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Routine Tasks</p>
                  <p style="color: #acb0c3">
                    Routine tasks you carry out every week according to your job
                    description. Minimum of 3 and Max of 5. For tech these are
                    tasks you have been instructed to carry out by Management. -
                    50 Points <span class="text-danger">*</span>
                  </p>
                </div>

                <button
                  class="btn btn-outline-success btn-xs"
                  @click.prevent="addField(routineTasks)"
                >
                  <i class="fa fa-plus"></i>
                </button>

                <div>
                  <b-input-group
                    v-for="(input, index) in routineTasks"
                    :key="`routineTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="routineTasks[index]"
                      :disabled="
                        routineTasks.length < routineCount ||
                        index < routineCount
                      "
                    ></b-form-textarea>

                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        v-show="
                          routineTasks.length > routineCount &&
                          index >= routineCount
                        "
                        @click="removeField(index, routineTasks)"
                        ><i class="fa fa-times"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5" v-if="outstandingTasks.length > 0">
                <div>
                  <p class="font-weight">Outstanding Tasks</p>
                  <p style="color: #acb0c3">
                    Outstanding Tasks from the previous week - Completion of
                    outstanding tasks from the previous week - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <b-input-group
                    v-for="(input, index) in outstandingTasks"
                    :key="`outstandingTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="outstandingTasks[index]"
                      readonly
                    ></b-form-textarea>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">New Tasks</p>
                  <p style="color: #acb0c3">
                    Tasks given to you during the week that may or may not be
                    directly related to your job description - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <button
                  class="btn btn-outline-success btn-xs"
                  @click.prevent="addField(newTasks)"
                >
                  <i class="fa fa-plus"></i>
                </button>

                <div>
                  <b-input-group
                    v-for="(input, index) in newTasks"
                    :key="`newTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="newTasks[index]"
                    ></b-form-textarea>

                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        v-show="newTasks.length > 1"
                        @click="removeField(index, newTasks)"
                        ><i class="fa fa-times"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Any Other Tasks</p>
                  <p style="color: #acb0c3">
                    Pro active tasks you give yourself during the week that move
                    GeroCare forward. - 10 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <button
                  class="btn btn-outline-success btn-xs"
                  @click.prevent="addField(otherTasks)"
                >
                  <i class="fa fa-plus"></i>
                </button>

                <div>
                  <b-input-group
                    v-for="(input, index) in otherTasks"
                    :key="`otherTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="otherTasks[index]"
                    ></b-form-textarea>

                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        v-show="otherTasks.length > 1"
                        @click="removeField(index, otherTasks)"
                        ><i class="fa fa-times"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label for="didntGoWell"
                    >What did not go well during the previous week and
                    why?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="didntGoWell"
                    id="didntGoWell"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label for="shouldKnowAbout"
                    >Anything that happened during the previous week we should
                    know about?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="shouldKnowAbout"
                    id="shouldKnowAbout"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div class="form-group">
                  <label for="suggestion"
                    >Any suggestion you have for Management or your
                    colleagues?</label
                  >
                  <textarea
                    class="form-control"
                    v-model="suggestion"
                    id="suggestion"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 text-center">
                <button
                  type="button"
                  @click.prevent="saveNewTask()"
                  class="btn btn-success"
                  :disabled="loading"
                >
                  <b-spinner small v-if="loading"></b-spinner>
                  <span v-else>Submit <i class="fa fa-paper-plane"></i> </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import _ from "lodash";
import {
  DEPARTMENTTASKS,
  INDIVIDUALTASKS
} from "@/models/tasks";
import { mapGetters } from "vuex";

var moment = require("moment");

export default {
  name: "NewTask",

  computed: {
    ...mapGetters(["user"]),
  },

  data() {
    return {
      loadingReport: false,
      loading: false,

      routineTasks: [""],
      routineCount: 0,

      newTasks: [""],

      otherTasks: [""],

      outstandingTasks: [],

      didntGoWell: null,
      shouldKnowAbout: null,
      suggestion: null,
      date: null,
      prevScore: 0,
    };
  },

  methods: {
    addField(fieldType) {
      fieldType.push("");
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    fetchLastReport() {
      this.loadingReport = true;

      http
        .get(endpoints.LAST_TASK)
        .then((response) => {
          if (!_.isEmpty(response)) {
            this.prevScore = response.score;
            this.outstandingTasks = response.items.map((x) => {
              return x.description;
            });
          }
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        })
        .finally(() => {
          this.loadingReport = false;
        });
    },

    populateRoutineTasks() {
      if (INDIVIDUALTASKS[this.user.email.toLowerCase()]) {
        this.routineTasks = INDIVIDUALTASKS[this.user.email.toLowerCase()];
        this.routineCount = INDIVIDUALTASKS[this.user.email.toLowerCase()]?.length;
      } else {
        this.routineTasks = DEPARTMENTTASKS[this.user.role.toLowerCase()] || [""];
        this.routineCount = DEPARTMENTTASKS[this.user.role.toLowerCase()]?.length || 0;
      }
    },

    saveNewTask() {
      if (
        this.routineTasks.length == 1 &&
        this.newTasks.length == 1 &&
        this.otherTasks.length == 1 &&
        this.didntGoWell == null
      ) {
        this.$toast.error("Report is too scanty. Please try again.");
      } else {
        this.loading = true;
        http
          .post(endpoints.ADD_TASK, {
            routineTasks: this.routineTasks,
            newTasks: this.newTasks,
            otherTasks: this.otherTasks,
            outstandingTasks: this.outstandingTasks,
            didntGoWell: this.didntGoWell,
            shouldKnowAbout: this.shouldKnowAbout,
            suggestion: this.suggestion,
          })
          .then((response) => {
            this.loading = false;
            this.$toast.success(response);
            this.$router.push("/tasks");
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },
  },

  mounted() {
    this.date = moment().format("dddd, MMMM Do YYYY");
    this.fetchLastReport();
    this.populateRoutineTasks();
  },
};
</script>
